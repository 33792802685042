import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion';

import {AppWrap, MotionWrap} from '../../wrapper';
import {urlFor, client} from '../../client';
import {axios} from 'react-axios'

import './Work.scss';

const Work = () => {
  const [works, setWorks] = useState([])
  const [filterWorks, setFilterWorks] = useState([]);

  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({y:0, opacity:1});

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const query = '*[_type == "works"] | order(_createdAt desc)';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWorks(data);
    })
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWorks(works);
      } else {
        setFilterWorks(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <div className='app__work'>
      <h2 className='head-text'>Work</h2>

      <div className='app__work-filter'>
        <div className='app__work-filter-options'>
          {['All', 'Games', 'Websites', 'Apps', 'Design'].map((item, index) => (
            <div
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex ${activeFilter === item ? 'item-active' : ''}`}
              >
                {item}
            </div>
          ))}
        </div>

        <motion.div
          animate={animateCard}
          transition={{duration: 0.5, delayChildren: 0.5}}
          className="app__work-portfolio"
        >
          {filterWorks.map((work, index) => {
            return <motion.div 
                      animate={animateCard}
                      transition={{duration: 0.1, delayChildren: 0.1}} 
                      className='app__work-item app__flex' 
                      key={index}>
                    <div className='app__work-item-bg'>
                      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 274" width="100%" height="100%">
                        <defs>
                          <clipPath id="clip">
                            <path d="M231,228.23,155.5,271.92a30.33,30.33,0,0,1-30.36,0l-108.87-49A30.43,30.43,0,0,1,1.05,196.61L11.87,93.17A30.35,30.35,0,0,1,27,66.84L116.38,6.08a30.35,30.35,0,0,1,30.37,0l92.3,66.47a30.42,30.42,0,0,1,15.21,26.31L261,201.38C261,212.23,240.41,222.8,231,228.23Z" transform="translate(-1.05 -2)"/>
                          </clipPath>
                        </defs>
                        {work.imgUrl && <image className='clip' href={urlFor(work.imgUrl).url()} x="-100" y="0" height="100%"/>}
                        {!work.imgUrl && <path d="M231,228.23,155.5,271.92a30.33,30.33,0,0,1-30.36,0l-108.87-49A30.43,30.43,0,0,1,1.05,196.61L11.87,93.17A30.35,30.35,0,0,1,27,66.84L116.38,6.08a30.35,30.35,0,0,1,30.37,0l92.3,66.47a30.42,30.42,0,0,1,15.21,26.31L261,201.38C261,212.23,240.41,222.8,231,228.23Z" transform="translate(-1.05 -2)"/>}
                      </svg>
                    </div>
                    <div className='app__work-item-content'>
                      <h3>{work.title}</h3>
                      <p>{work.description}</p>
                      {work.codeLink && <a href={work.codeLink} target="_blank" rel="noreferrer">Source</a>}
                      &nbsp;
                      {work.projectLink && <a href={work.projectLink} target="_blank" rel="noreferrer">Details</a>}
                    </div>
                  </motion.div>;
          })}
        </motion.div>
      </div>
    </div>
  );
}

export default AppWrap(
  MotionWrap(Work, 'app__work'),
  'work',
  'app__yellowbg');