import React from 'react';

import { Navbar } from './components';
import {About, Footer, Header, Skills, Work} from './container'
import './App.scss';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-76027008-7";

const App = () => {
  ReactGA.initialize(TRACKING_ID);
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Footer />
    </div>
  );
}

export default App;