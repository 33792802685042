import React, {useState, useEffect} from 'react'

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { images } from '../../constants';
import { motion } from 'framer-motion';

import './About.scss';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"] | order(title desc)';
    client.fetch(query).then((data) => {
      setAbouts(data);
    })
  }, []);
  
  return (
    <div className='app__about'>
      <h2 className='head-text'>About</h2>
      <div className='app__about-content'>
        <div className='app__about-photo-container'>
          <img src={images.mainPhoto} alt="Fede Alvarez"/>
        </div>
        <div className='app__about-text'>
          <div className='app__profiles'>
            {abouts.map((about, index) => (
              <motion.div
                whileInView={{ opacity: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, type: 'tween' }}
                className="app__profile-item"
                key={about.title + "_" + index}
              >
                {about.imageurl && <img src={urlFor(about.imageurl)} alt={about.title} />}
                <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
                <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
              </motion.div>))}
          </div>
          <p>I am a self taught developer that loves coding and design process. In my spare time, I love to play guitar, draw & learn some new technologies.</p>
          <p>As Partner/Developer of <strong>Estudio Egg</strong> for 6 years, I've <strong>coded multiple educational and advertising games</strong>.</p>
          <p className='app__profile-important'>I've traveled South America living in <strong>La Camiona</strong> (<a href="https://www.instagram.com/la_camiona/" rel="noreferrer" target={"_blank"}>@la_camiona</a>) for <strong>4 years</strong>, working remotely doing some <i>graphic design, websites and apps</i> as a freelancer.</p>
          
        </div>
      </div>

      
    </div>
  );
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__pinkbg');