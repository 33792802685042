import React, {useState} from 'react'
import { motion } from 'framer-motion';
import {AppWrap} from '../../wrapper';
import { HomeAnimation } from '../../components';

import './Header.scss';



const pathVariants = {
  hidden: { rotate: 4 },
  visible: {
    rotate: -4,
    transition: { repeat: Infinity, repeatType: "mirror", duration:3 }
  }
};

const pathVariants2 = {
  hidden: { rotate: 4 },
  visible: {
    rotate: -4,
    transition: { repeat: Infinity, repeatType: "mirror", duration:2 }
  }
};

const pathVariants3 = {
  hidden: { rotate: 7 },
  visible: {
    rotate: -7,
    transition: { repeat: Infinity, repeatType: "mirror", duration:4 }
  }
};

const Header = () => {
  const [interest, setInterest] = useState('music');
  const myInterests = ['illustration', 'music', 'traveling', 'pixel art', 'UI/UX design', 'video games'];

  const getRandomInterest = () => {
    setInterval(() => {
      const randIndex = Math.floor(Math.random()*myInterests.length);
      setInterest(myInterests[randIndex]);
    }, 5000);
  };

  React.useEffect(() => {
    getRandomInterest();
  }, []);

  return (
    <div className="app__header app__flex">
        <HomeAnimation/>
        <div className='app__header-info'>
          <h1>Hi! I'm Federico!</h1>
          <span>Game & Web Developer</span>
          <p>Currently developing games, websites & apps.<br />
            In love with &nbsp;<motion.strong
              
              transition={{ duration: 2, repeat: Infinity }} 
              animate={{
                        originY:0.5, 
                        scaleY:2,
                        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)"}}>{interest}
              </motion.strong>
          </p>
        </div>
    </div>
  );
}

export default AppWrap(Header, 'home');