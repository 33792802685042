import React, {useState} from 'react'
import { AppWrap, MotionWrap } from '../../wrapper';
import { motion } from 'framer-motion';
import {client} from '../../client';
import emailjs from '@emailjs/browser';
import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';
import {BsTwitter, BsGithub, BsLinkedin} from 'react-icons/bs';
import {FaDribbble, FaItchIo} from 'react-icons/fa';
import './Footer.scss';
import { SocialMedia } from '../../components';

const pathVariants = {
  hidden: { rotate: 4 },
  visible: {
    rotate: -4,
    transition: { repeat: Infinity, repeatType: "mirror", duration:3 }
  }
};

const pathVariants2 = {
  hidden: { rotate: 6 },
  visible: {
    rotate: -6,
    transition: { repeat: Infinity, repeatType: "mirror", duration:2 }
  }
};

const pathVariants3 = {
  hidden: { rotate: 8 },
  visible: {
    rotate: -8,
    transition: { repeat: Infinity, repeatType: "mirror", duration:5 }
  }
};

const Footer = () => {
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({name:'', email: '', message: ''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const {name, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name] : value});
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message
    };

    if (name.trim() === "" || email.trim() === "" || message.trim() === "") {
      setLoading(false);
      setFormError("All fields are required");
      return;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(email))  {
      setFormError("Email is not valid");
      return;
    }

    setFormError(null);

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);

      gaEventTracker('email');
      
      emailjs.send('service_dxtfp6h', process.env.REACT_APP__EMAIL_TEMPLATE, 
      {
        name: contact.name,
        email: contact.email,
        message: contact.message
      }, process.env.REACT_APP__EMAIL_API);

      
    });

  };

  return (
    <>
      <div className='footer__image'>
        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.96 477.28" initial="hidden" animate="visible">
          <motion.path variants={pathVariants} style={{fill:"#ff88ec", opacity:0.2}} d="M198.37,444.84,98.15,353.38a47.19,47.19,0,0,1-14.29-45l21.44-184.3a47.22,47.22,0,0,1,31.82-34.87L295.44,56.81a47.21,47.21,0,0,1,46.11,10.13l132,104a47.22,47.22,0,0,1,14.28,45l-55.08,167.9A47.2,47.2,0,0,1,401,418.7L252.21,476.62C236.14,481.72,210.83,456.21,198.37,444.84Z"/>
          <motion.path variants={pathVariants2} style={{fill:"#ffce00", opacity:0.8}} d="M404.14,367.65,279.43,421.09a47.21,47.21,0,0,1-46.88-5.6L73.61,319.77A47.23,47.23,0,0,1,55,276.37L91,118.82A47.22,47.22,0,0,1,119.26,81L268.54,3.82a47.19,47.19,0,0,1,46.87,5.59L445.51,129a47.19,47.19,0,0,1,18.6,43.39l-8.77,159.39C453.34,348.51,419.64,361,404.14,367.65Z"/>
          <motion.path variants={pathVariants3} style={{fill:"#ff88ec", opacity:0.4}} d="M303.45,426.35,168,435a47.21,47.21,0,0,1-42.31-20.93L7.92,270.77a47.21,47.21,0,0,1-3-47.11L91.44,87.18A47.19,47.19,0,0,1,130.73,61L297.22,38.12a47.2,47.2,0,0,1,42.31,20.94l82.66,156.18a47.19,47.19,0,0,1,3,47.11l-61.53,147.3C356.21,424.76,320.28,425.27,303.45,426.35Z"/>  
        </motion.svg>
        <h2 className='head-text'>Let's connect!</h2>
        <div className='footer__socials'>
          <div>
            <a href="https://fedealvarez.itch.io/" target="_blank" rel="noreferrer"><FaItchIo /></a>
          </div>
          <div>
            <a href="https://github.com/fede-alvarez" target="_blank" rel="noreferrer"><BsGithub /></a>
          </div>
          <div>
            <a href="https://twitter.com/FefoAlvarez" target="_blank" rel="noreferrer"><BsTwitter /></a>
          </div>
          <div>
            <a href="https://dribbble.com/fedealvarez" target="_blank" rel="noreferrer"><FaDribbble /></a>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/federicoalvarez/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
          </div>
      </div>
      </div>

      {!isFormSubmitted ? 
      <div>
          {formError && <div className='footer__form-error'>{formError}</div>}
          <ul className="footer__form">
            <li><input name="name" value={name} type="text" placeholder="Your name" required onChange={handleChangeInput} /></li>
            <li><input name="email" value={email} type="email" placeholder="Your email" required onChange={handleChangeInput} /></li>
            <li><textarea name="message" value={message} rows="5" placeholder="Your message" onChange={handleChangeInput}></textarea></li>
            <li><button onClick={handleSubmit}>{loading ? "Sending" : "Connect"}</button></li>
          </ul>
      </div> : <div><h3 className='head-text' style={{color:'#F3F3F3'}}>Thank you for getting in touch!</h3></div>}
    </>
  );
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__greenbg');