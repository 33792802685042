import React from 'react'
import {BsTwitter, BsGithub, BsLinkedin} from 'react-icons/bs';
import {FaDribbble, FaItchIo} from 'react-icons/fa';

function SocialMedia() 
{
  return (
    <div className='app__social'>
      <div>
        <a href="https://fedealvarez.itch.io/" target="_blank" rel="noreferrer"><FaItchIo /></a>
      </div>
      <div>
        <a href="https://github.com/fede-alvarez" target="_blank" rel="noreferrer"><BsGithub /></a>
      </div>
      <div>
        <a href="https://twitter.com/FefoAlvarez" target="_blank" rel="noreferrer"><BsTwitter /></a>
      </div>
      <div>
        <a href="https://dribbble.com/fedealvarez" target="_blank" rel="noreferrer"><FaDribbble /></a>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/federicoalvarez/" target="_blank" rel="noreferrer"><BsLinkedin /></a>
      </div>
    </div>
  )
}

export default SocialMedia