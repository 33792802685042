import React from "react";

import Spline from '@splinetool/react-spline';

import './HomeAnimation.scss';


const HomeAnimation = () => {
  
  return (
    <div className="app_anim">
      <Spline scene="https://prod.spline.design/NIrpMdWyORxXmf1s/scene.splinecode"  />
    </div>
  );

}

export default HomeAnimation;

