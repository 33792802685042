import React from 'react'

const Logo = ({ fill, width }) => {
  return (
    <svg width={width ? width : '150'} viewBox="0 0 300 109" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.88 55.064V60.32H26.896V83H20.632V32.6H44.68V37.856H26.896V55.064H42.88ZM76.1468 32.6V37.856H58.3628V54.344H74.3468V59.6H58.3628V77.744H76.1468V83H52.0988V32.6H76.1468ZM109.342 71.624C109.342 75.752 108.382 78.68 106.462 80.408C104.59 82.136 101.47 83 97.1016 83H82.2696V32.6H96.2376C100.558 32.6 103.822 33.488 106.03 35.264C108.238 36.992 109.342 39.848 109.342 43.832V71.624ZM103.078 43.184C103.078 41.6 102.598 40.352 101.638 39.44C100.678 38.48 99.4056 38 97.8216 38H88.5336V77.6H97.7496C99.5736 77.6 100.918 77.192 101.782 76.376C102.646 75.512 103.078 74.168 103.078 72.344V43.184ZM139.512 32.6V37.856H121.728V54.344H137.712V59.6H121.728V77.744H139.512V83H115.464V32.6H139.512ZM162.771 75.8V83H155.571V75.8H162.771ZM183.87 70.256L181.062 83H174.654L186.822 32.6H195.102L207.054 83H200.646L197.91 70.256H183.87ZM196.758 64.856L190.854 37.352L185.022 64.856H196.758ZM215.337 46.64C215.337 41.072 216.033 36.56 217.425 33.104C218.865 29.648 220.857 26.6 223.401 23.96H229.737C227.433 26.744 225.729 29.096 224.625 31.016C223.521 32.888 222.705 35 222.177 37.352C221.697 39.704 221.457 42.752 221.457 46.496V69.104C221.457 72.848 221.697 75.896 222.177 78.248C222.705 80.6 223.521 82.712 224.625 84.584C225.729 86.504 227.433 88.856 229.737 91.64H223.401C220.857 89 218.865 85.952 217.425 82.496C216.033 79.04 215.337 74.528 215.337 68.96V46.64ZM261.42 68.96C261.42 74.528 260.7 79.04 259.26 82.496C257.868 85.952 255.9 89 253.356 91.64H247.02C249.324 88.856 251.028 86.504 252.132 84.584C253.236 82.712 254.028 80.6 254.508 78.248C255.036 75.896 255.3 72.848 255.3 69.104V46.496C255.3 42.752 255.036 39.704 254.508 37.352C254.028 35 253.236 32.888 252.132 31.016C251.028 29.096 249.324 26.744 247.02 23.96H253.356C255.9 26.6 257.868 29.648 259.26 33.104C260.7 36.56 261.42 41.072 261.42 46.64V68.96ZM290.942 47V54.2H283.742V47H290.942ZM283.742 83V75.8H290.942V83L286.478 93.08H280.862L283.742 83Z" fill={fill ? fill : "#ff88e9"}/>
    </svg>
  )
}

export default Logo